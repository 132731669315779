import React from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageHeader from '../../components/pageHeader'
import EducationRegistration from '../../components/educationRegistration'
const Anmalan = (props) => (
  <Layout>
    <Seo title='Utbildningsanmälan' />
    <PageHeader text='Utbildningsanmälan' />
    <EducationRegistration />
  </Layout>
)

export default Anmalan
