import React from 'react'
import { Link } from 'gatsby'
import * as EducationRegistrationStyle from './css/educationRegistration.module.css'

function EducationRegistration (props) {
  return (
    <>

      <div className='container'>
        <div className='row'>
        <div className={` col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ${EducationRegistrationStyle.form}`}>
            <div>
              <h2 className='pt-1'> Välj den utbildning/kurs anmälan avser.</h2>
              <p className='m-0'>
                Vi hör av oss så fort vi får tid och möjligthet.<br />
                Har du något övrigt önskemål som vi ska ta hänsyn
                till går det bra att skriva det under övriga synpunkter
              </p>
              <p className='m-2'>
                <i>
                  Personbilsutbildning är till för dig som vill skriva in
                  sig hos oss på trafikskolan börja ta körlektioner för B-behörighet.
                </i>
              </p>
              <p className='m-0'>
                <i>
                  Osäker på din utbildningsnivå? <br /> läs mera om <Link to='/utbildningar/testlektion'>testlektion</Link>
                </i>
              </p>
            </div>


          
            <form name='Utbildningsanmälan' method='POST' data-netlify='true' action='/info/tack'>
              <input type='hidden' name='form-name' value='Utbildningsanmälan' />
              <label htmlFor='inlineFormCustomSelectPref'>Gäller utbildning/kurs</label>

              <select className='custom-select' id='inlineFormCustomSelectPref' name='Utbildning/kurs' required>

                <option defaultValue=''>Välj utbildning</option>
                <option value='Personbilsutbildning'>Personbilsutbildning</option>
                <option value='Testlektion'>Testlektion</option>
                <option value='Individuell intensivutbildning'>Individuell intensivutbildning</option>
                <option value='Intensivkurs 2 veckor'>Intensivkurs 2 veckor</option>
                <option value='Riskutbildning del 1'>Riskutbildning del 1</option>
                <option value='Riskutbildning del 2'>Riskutbildning del 2</option>
                <option value='Teorikurs'>Teorikurs</option>
                <option value='Handledarkurs'>Handledarkurs</option>
                <option value='Övrigt'>Övrigt</option>
              </select>

              <div className='form-group mt-3'>
                <label htmlFor='namn'>Namn</label>
                <input type='text' className='form-control' placeholder='Namn' name='Namn' required />
              </div>

              <div className='form-group'>
                <label htmlFor='personnummer'>Personnummer</label>
                <input type='text' className='form-control' placeholder='ååmmdd-nnnn' name='Personnummer' required />
              </div>

              <div className='form-group'>
                <label htmlFor='telefon'>Telefon</label>
                <input type='text' className='form-control' placeholder='Telefon' name='Telefon' required />
              </div>
              <div className='form-group'>
                <label htmlFor='e-postadress'>E-postadress</label>
                <input type='email' className='form-control' id='inputEmail' placeholder='E-postadress' name='E-postadress' required />
              </div>
              <div className='form-group'>
                <label htmlFor='textarea'>Övriga synpunkter</label>
                <textarea className='form-control' id='textarea' rows='3' name='Övriga synpunkter' />
              </div>
              <button type='submit' className='btn btn-danger'>Skicka</button>
            </form>
          </div>
        </div>
      </div>

    </>
  )
}

export default EducationRegistration
